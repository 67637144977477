'use client'; // Error boundaries must be Client Components
import {Button} from '@prescriberpoint/ui';
import dynamic from 'next/dynamic';
import {usePathname} from 'next/navigation';
import {useEffect} from 'react';
import {useToggle} from 'usehooks-ts';
import {COOKIE_CONSENT_ERROR} from '@/components/Footer/CookieConsent';
import MainLayout from '@/layouts/MainLayout';

const CookieConsent = dynamic(
  () => import('@/components/Footer/CookieConsent'),
);

const ErrorPage = dynamic(() => import('@/components/ErrorPage'));

interface ErrorHandlerProps {
  error: Error & {digest?: string};
  reset: () => void;
}

const ErrorContent = ({error, reset}: ErrorHandlerProps) => {
  const [isCookieModalOpen, toogleCookieModal] = useToggle();

  return error?.message === COOKIE_CONSENT_ERROR ? (
    <>
      <Button onClick={toogleCookieModal} size='lg' id='enable_cookies'>
        Enable Cookies
      </Button>
      <CookieConsent
        isModalActive={isCookieModalOpen}
        toggleModal={toogleCookieModal}
        onSave={reset}
      />
    </>
  ) : (
    <Button onClick={reset} size='lg' id='try_again'>
      Try Again ?
    </Button>
  );
};

export default function ErrorHandler({
  error,
  reset,
}: Readonly<ErrorHandlerProps>) {
  const pathname = usePathname();

  useEffect(() => {
    // Log the error to new Relic
    window?.newrelic?.noticeError(error, {
      message: error?.message,
      errorSource: pathname,
    });
  }, [error, pathname]);

  return (
    <MainLayout>
      <ErrorPage
        errorCode={null}
        errorMessage={error?.message}
        ctaComponent={<ErrorContent error={error} reset={reset} />}
      />
    </MainLayout>
  );
}
